<div mat-dialog-content>

    <mat-card class="trinta-card">
        <h1 class="text-2xl font-extrabold text-gray-900" style="color: var(--darkColor)">Crear Empresa</h1>

    
        <mat-card-content class="mt-5 w-full">
            <form class="mt-5 w-full">
                <mat-form-field class="w-full mb-4">
                    <i-feather name="edit-3"></i-feather>
                    <mat-label>Id de la empresa</mat-label>
                    <input (keypress)="restrictSpecialChars($event)" (paste)="restrictSpecialCharsPaste($event)" [formControl]="nameControl" matInput placeholder="Nombre de la empresa">
                    <mat-error *ngIf="!nameControl.valid && nameControl.touched">Ingrese un nombre</mat-error>
                </mat-form-field>
                <mat-form-field class="w-full mb-4">
                    <i-feather name="edit-3"></i-feather>
                    <mat-label>DisplayName de la empresa</mat-label>
                    <input [formControl]="displayNameControl" matInput placeholder="Nombre de la empresa">
                    <mat-error *ngIf="!displayNameControl.valid && displayNameControl.touched">Ingrese un nombre</mat-error>
                </mat-form-field>
                <mat-form-field class="w-full mb-4">
                    <i-feather name="edit-3"></i-feather>
                    <mat-label>Descripcion de la empresa</mat-label>
                    <input [formControl]="descriptionControl" matInput placeholder="Nombre de la empresa">
                    <mat-error *ngIf="!descriptionControl.valid && descriptionControl.touched">Ingrese un nombre</mat-error>
                </mat-form-field>
                <mat-form-field class="w-full mb-4">
                    <i-feather name="file"></i-feather>
                    <mat-label>RUT de la empresa</mat-label>
                    <input [formControl]="rutControl" matInput placeholder="RUT de la empresa">
                    <mat-error *ngIf="!rutControl.valid && rutControl.touched">Ingrese un RUT válido</mat-error>
                </mat-form-field>
            </form>
        </mat-card-content>
    
        <div mat-dialog-actions class="mt-0 flex flex-row flex-nowrap justify-end space-x-3 w-full" style="justify-content: end;">
            <button mat-button color="primary" (click)="onNoClick()">
                Cancelar
            </button>
            <button mat-flat-button color="primary" cdkFocusInitial (click)="onCreate()" [disabled]="onboardingForm.invalid">
                Crear
            </button>
        </div>
    </mat-card>
</div>